html, body {
    height: 100%;
    width: 100%;
    background-color: #fafafb;
    font-family: "Helvetica Neue", Helvetica, Arial, sans-serif;
    overflow: auto;
}
button{
    cursor: pointer;
}

.page-container{
    position: absolute; /* Posición absoluta */
    top: 100px; /* Se sitúa en la parte superior del contenedor padre */
    left: 50%; /* Se sitúa en la mitad del contenedor padre */
    transform: translateX(-50%); /* Se centra solo horizontalmente */
    width: 80%; /* Ocupa el 80% del ancho de su contenedor */
    max-width: 1200px; /* Pero no más de 1200px */
    min-height: 80%;
    background-color: #fff; /* Fondo blanco */
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.2); /* Sombra */
    border-radius: 5px; /* Bordes un poco redondeados */
    padding: 20px; /* Padding adecuado */

  }