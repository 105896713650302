._dashboard-container{
    position: relative;
    display: flex;
    top: 1.5vh;
    left: 20%;
    width: max-content;
    height: max-content;
    color: #455560;
    transition: .3s;
}

._OCS-dashboard-container{
    position: relative;
    display: flex;
    top: 1.5vh;
    left: 7%;
    width: max-content;
    height: max-content;
    color: #455560;
    transition: .3s;
}

._CUDD-cards{
    max-width: 850px;
}

._OCS-CUDD-cards{
    max-width: 1000px;
}



._customers-card, ._users-card, ._devices-card, ._deliverys-graf, ._documents-card, ._groups-card{
    background-color: #fff;
    padding-left: 8px;
    padding-right: 8px;
    line-height: 1.5;
    border: 1px solid #e6ebf1;
    border-radius: .635rem;
    transition: 0.5s;
}
._OCS-customers-card, ._OCS-users-card, ._OCS-devices-card, ._OCS-deliverys-graf, ._OCS-documents-card, ._OCS-groups-card{
    background-color: #fff;
    padding-left: 8px;
    padding-right: 8px;
    line-height: 1.5;
    border: 1px solid #e6ebf1;
    border-radius: .635rem;
    transition: 0.5s;
}

._customers-card, ._users-card, ._devices-card{
    height: 151px;
    width: 281px;
    margin: 0 8px 20px 8px !important;
    font-size: 14px;
}

._OCS-customers-card, ._OCS-users-card, ._OCS-devices-card{
    height: 151px;
    width: 337px;
    margin: 0 8px 20px 8px !important;
    font-size: 14px;
}

._deliverys-graf{
    overflow: unset;
    margin: 0 8px 20px 8px !important;
    width: 818px;
    transition: 0.3s;
}
._OCS-deliverys-graf{
    overflow: unset;
    margin: 0 8px 20px 8px !important;
    width: 980px;
    transition: 0.3s;
}

._card-header{
    font-size: 17px;
    font-weight: 700;
    padding-top: 8px;
    padding-left: 8px;
    margin-top: 10px;
    margin-bottom: 0;
}

._Dgraf-header{
    margin-bottom: -15px;
}

._DG-cards{
    position: static;
    display: inline-block;
    width: 310px;
    height: 558px;
    transition: .5s;
}
._OCS-DG-cards{
    position: static;
    display: inline-block;
    width: 380px;
    height: 558px;
    transition: .5s;
}

._documents-card, ._groups-card{
    margin: 0 0 20px 0;
    text-align: center;
}
._OCS-documents-card, ._OCS-groups-card{
    margin: 0 0 20px 0;
    text-align: center;
}

._documents-card{
    height: 350px;
}

._groups-card{
    height: 274px;
}

._customer-card-data{
    display: flex;
    flex-direction: row;
    justify-content: left;
}

._customer-data{
    color: #1A3353;
    font-size: 30px;
    font-weight: 700 ;
    margin-left: 8px;
    margin-bottom: 5px;
}

._data-difference{
    font-size: 17px;
    font-weight: 700;
    margin-top: 10px;
    margin-left: 20px;
}

._data-difference-positive{
    color: #04d182;
}

._data-difference-negative{
    color: #ff6b72;
}

._data-difference-neutral {
    color: #008ffb
}

._customer-data-arrow{
    margin-top: 12px;
}

._customers-card-footer{
    color: #72849a;
    font-size: 14px;
}
._ant-row{
    display: flex;
    flex-direction: row;
}

._documents-header{
    margin-bottom: 20px;
}

@media screen and (max-width: 1490px){
    ._CUDD-cards{
        max-width: 800px;
    }
    
    ._OCS-CUDD-cards{
        max-width: 950px;
    }
    ._OCS-customers-card, ._OCS-users-card, ._OCS-devices-card{
        height: 151px;
        width: 290px;
        margin: 0 8px 15px 8px !important;
        font-size: 14px;
    }
    ._deliverys-graf{
        width: 780px;
    }
    ._OCS-deliverys-graf{
        margin: 0 8px 20px 8px !important;
        width: 890px;
        transition: 0.3s;
    }
}
@media screen and (max-width: 1400px){
    ._dashboard-container{
        left: 280px;
    }
    
    ._OCS-dashboard-container{
        left: 90px;
    }
    ._CUDD-cards{
        max-width: 700px;
    }
    
    ._OCS-CUDD-cards{
        max-width: 850px;
    }
    ._OCS-customers-card, ._OCS-users-card, ._OCS-devices-card{
        height: 151px;
        width: 290px;
        margin: 0 8px 15px 8px !important;
        font-size: 14px;
    }
    ._deliverys-graf{
        width: 680px;
    }
    ._OCS-deliverys-graf{
        width: 820px;
    }
    ._DG-cards{
        width: 280px;
        height: 558px;
    }
    ._OCS-DG-cards{
        width: 320px;
        height: 558px;
    }
}
@media screen and (max-width: 1270px){
    ._dashboard-container{
        left: 280px;
    }
    
    ._OCS-dashboard-container{
        left: 90px;
    }
    ._CUDD-cards{
        max-width: 580px;
    }
    
    ._OCS-CUDD-cards{
        max-width: 700px;
    }
    ._OCS-customers-card, ._OCS-users-card, ._OCS-devices-card{
        height: 151px;
        width: 220px;
        margin: 0 8px 15px 8px !important;
        font-size: 14px;
    }
    ._deliverys-graf{
        width: 555px;
    }
    ._OCS-deliverys-graf{
        width: 680px;
    }
    ._DG-cards{
        width: 232px;
        height: 558px;
    }
    ._OCS-DG-cards{
        width: 300px;
        height: 558px;
    }
}
@media screen and (max-width: 1100px){
    ._dashboard-container{
        left: 280px;
        display: inline-block;
    }
    
    ._OCS-dashboard-container{
        left: 90px;
        display: inline-block;

    }
    ._CUDD-cards{
        max-width: 700px;
    }
    
    ._OCS-CUDD-cards{
        max-width: 900px;
    }
    ._OCS-customers-card, ._OCS-users-card, ._OCS-devices-card{
        height: 151px;
        width: 410px;
        margin: 0 8px 15px 8px !important;
        font-size: 14px;
    }
    ._deliverys-graf{
        width: 685px;
    }
    ._OCS-deliverys-graf{
        width: 885px;
    }
    ._DG-cards{
        display: flex;
        width: 685px;
    }
    ._documents-card{
        width: 456px;
        margin-right: 15px;
    }
    ._groups-card{
        height: 350px;
        width: 456px;
    }
    ._OCS-DG-cards{
        display: flex;
        width: 1000px
    } 
    ._OCS-documents-card {
        height: 350px;
        width: 428px;
        margin: 0 18px 20px 10px;
    }
    ._OCS-groups-card{
        height: 350px;
        width: 428px;
        margin: 0 8px 20px 10px;
    }
}
@media screen and (max-width: 990px){
    ._dashboard-container{
        left: 280px;
        display: inline-block;
    }
    
    ._OCS-dashboard-container{
        left: 90px;
        display: inline-block;

    }
    ._CUDD-cards{
        max-width: 580px;
    }
    
    ._OCS-CUDD-cards{
        max-width: 770px;
    }
    ._OCS-customers-card, ._OCS-users-card, ._OCS-devices-card{
        height: 151px;
        width: 400px;
        margin: 0 6px 12px 6px !important;
        font-size: 14px;
    }
    ._deliverys-graf{
        width: 564px;
    }
    ._OCS-deliverys-graf{
        width: 756px;
    }
    ._DG-cards{
        display: flex;
    }
    ._documents-card{
        height: 350px;
        width: 260px;
        margin: 0 40px 20px 10px;
    }
    ._groups-card{
        height: 350px;
        width: 260px;
    }
    ._OCS-DG-cards{
        display: flex;
    }
    ._OCS-documents-card {
        height: 350px;
        width: 365px;
        margin: 0 18px 20px 10px;
    }
    ._OCS-groups-card{
        height: 350px;
        width: 365px;
        margin: 0 8px 20px 10px;
    }
}
@media screen and (max-width: 860px){
    ._dashboard-container{
        left: 280px;
        display: inline-block;
    }
    
    ._OCS-dashboard-container{
        left: 90px;
        display: inline-block;

    }
    ._CUDD-cards{
        max-width: 475px;
    }
    
    ._OCS-CUDD-cards{
        max-width: 670px;
    }
    ._customers-card, ._users-card, ._devices-card{
        height: 150px;
        width: 142px;
        margin: 0 2px 20px 2px !important;
        font-size: 14px;
    }
    ._OCS-customers-card, ._OCS-users-card, ._OCS-devices-card{
        height: 161px;
        width: 200px;
        margin: 0 6px 12px 6px !important;
        font-size: 13px;
    }
    ._data-difference{
        margin-left: 10px;
    }
    ._customers-card-footer{
        font-size: 12px;
    }
    ._deliverys-graf{
        width: 430px;
    }
    ._OCS-deliverys-graf{
        width: 620px;
    }
    ._DG-cards{
        width: 400px;
        display: flex;
        flex-direction: column;
    }
    ._documents-card{
        height: 350px;
        width: 430px;
        margin: 0 20px 20px 10px;
    }
    ._groups-card{
        height: 350px;
        width: 430px;
    }
    ._OCS-DG-cards{
        display: flex;
    }
    ._OCS-documents-card {
        height: 350px;
        width: 295px;
        margin: 0 18px 20px 10px;
    }
    ._OCS-groups-card{
        height: 350px;
        width: 295px;
        margin: 0 8px 20px 10px;
    }
    ._customer-data{
        font-size: 25px;
        font-weight: 700 ;
        margin-left: 8px;
        margin-bottom: 5px;
    }
}
@media screen and (max-width: 730px){
    ._dashboard-container{
        left: 280px;
        display: inline-block;
    }
    
    ._OCS-dashboard-container{
        left: 90px;
        display: inline-block;

    }
    ._CUDD-cards{
        max-width: 475px;
    }
    
    ._OCS-CUDD-cards{
        max-width: 670px;
    }
    ._customer-data{
        font-size: 20px;
        font-weight: 550 ;
        margin-left: 8px;
        margin-bottom: 5px;
    }
    ._data-difference{
        font-size: 16px;
        font-weight: 550;
        margin-top: 6px;
        margin-left: 10px;
    }
    ._customer-data-arrow{
        margin-top: 8px;
    }
    
    ._customers-card-footer{
        font-size: 12px;
    }
    ._ant-row{
        display: flex;
        flex-direction: row;
    }
    ._customers-card, ._users-card, ._devices-card{
        height: 165px;
        width: 125px;
        margin: 0 2px 20px 2px !important;
    }
    ._OCS-customers-card, ._OCS-users-card, ._OCS-devices-card{
        height: 161px;
        width: 185px;
        margin: 0 4px 12px 4px !important;
        font-size: 14px;
    }
    ._deliverys-graf{
        width: 375px;
    }
    ._OCS-deliverys-graf{
        width: 565px;
    }
    ._DG-cards{
        display: flex;
        flex-direction: column;
        width: 420px;
    }
    ._documents-card {
        height: 350px;
        width: 375px;
        margin: 0 18px 20px 10px;
    }
    ._groups-card{
        height: 350px;
        width: 375px;
        margin: 0 8px 20px 10px;
    }
    ._OCS-DG-cards{
        display: flex;
        width: 620px;
    }
    ._OCS-documents-card {
        height: 350px;
        width: 266px;
        margin: 0 18px 20px 10px;
    }
    ._OCS-groups-card{
        height: 350px;
        width: 266px;
        margin: 0 8px 20px 10px;
    }
}
@media screen and (max-width: 610px){
    ._dashboard-container{
        left: 280px;
        display: inline-block;
    }
    
    ._OCS-dashboard-container{
        left: 90px;
        display: inline-block;

    }
    ._CUDD-cards{
        max-width: 475px;
    }
    
    ._OCS-CUDD-cards{
        max-width: 670px;
    }
    ._card-header{
        font-size: 20px;
    }
    ._customer-data{
        font-size: 25px;
        font-weight: 550 ;
        margin-left: 8px;
        margin-bottom: 5px;
    }
    ._data-difference{
        font-size: 16px;
        font-weight: 550;
        margin-top: 6px;
        margin-left: 10px;
    }
    ._customer-data-arrow{
        margin-top: 8px;
    }
    
    ._customers-card-footer{
        font-size: 15px;
    }
    ._ant-row{
        display: flex;
        flex-direction: column;
    }
    ._customers-card, ._users-card, ._devices-card{
        height: 165px;
        width: 290px;
        margin: 0 2px 20px 2px !important;
    }
    ._OCS-customers-card, ._OCS-users-card, ._OCS-devices-card{
        height: 151px;
        width: 470px;
        margin: 0 4px 12px 4px !important;
        padding-left: 30px;
        font-size: 14px;
    }
    ._Dgraf-header{
        margin-bottom: 10px;
    }
    ._deliverys-graf{
        width: 290px;
    }
    ._OCS-deliverys-graf{
        width: 480px;
    }
    ._DG-cards{
        display: flex;
        flex-direction: column;
        max-width: 290px;
    }
    ._documents-card {
        height: 350px;
        width: 290px;
        margin: 0 18px 20px 10px;
    }
    ._groups-card{
        height: 350px;
        width: 290px;
        margin: 0 8px 20px 10px;
    }
    ._OCS-DG-cards{
        display: flex;
        flex-direction: column;
        width: 490px;
    }
    ._OCS-documents-card {
        height: 350px;
        width: 480px;
        margin: 0 18px 20px 10px;
    }
    ._OCS-groups-card{
        height: 350px;
        width: 480px;
        margin: 0 8px 20px 10px;
    }
}
@media screen and (max-width: 490px){
    ._dashboard-container{
        left: 280px;
        display: inline-block;
    }
    
    ._OCS-dashboard-container{
        left: 90px;
        display: inline-block;

    }
    ._CUDD-cards{
        max-width: 475px;
    }
    
    ._OCS-CUDD-cards{
        max-width: 670px;
    }
    ._card-header{
        font-size: 18px;
    }
    ._customer-data{
        font-size: 20px;
        font-weight: 550 ;
        margin-left: 8px;
        margin-bottom: 5px;
    }
    ._data-difference{
        font-size: 16px;
        font-weight: 550;
        margin-top: 6px;
        margin-left: 10px;
    }
    ._customer-data-arrow{
        margin-top: 8px;
    }
    
    ._customers-card-footer{
        font-size: 14px;
    }
    ._ant-row{
        display: flex;
        flex-direction: column;
    }
    ._customers-card, ._users-card, ._devices-card{
        height: 151px;
        width: 370px;
        margin: 0 4px 12px 4px !important;
    }
    ._OCS-customers-card, ._OCS-users-card, ._OCS-devices-card{
        height: 151px;
        width: 370px;
        margin: 0 4px 12px 4px !important;
        padding-left: 30px;
        font-size: 14px;
    }
    ._Dgraf-header{
        margin-bottom: 10px;
    }
    ._deliverys-graf{
        width: 365px;
    }
    ._OCS-deliverys-graf{
        width: 365px;
    }
    ._DG-cards{
        display: flex;
        flex-direction: column;
        max-width: 290px;
    }
    ._documents-card {
        height: 350px;
        width: 290px;
        margin: 0 18px 20px 10px;
    }
    ._groups-card{
        height: 350px;
        width: 290px;
        margin: 0 8px 20px 10px;
    }
    ._OCS-DG-cards{
        display: flex;
        flex-direction: column;
        width: 490px;
    }
    ._OCS-documents-card {
        height: 350px;
        width: 365px;
        margin: 0 18px 20px 10px;
    }
    ._OCS-groups-card{
        height: 350px;
        width: 365px;
        margin: 0 8px 20px 10px;
    }
}
@media screen and (max-width: 430px){
    ._dashboard-container{
        left: 280px;
        display: inline-block;
    }
    
    ._OCS-dashboard-container{
        left: 83px;
        display: inline-block;

    }
    ._CUDD-cards{
        max-width: 475px;
    }
    
    ._OCS-CUDD-cards{
        max-width: 670px;
    }
    ._card-header{
        font-size: 18px;
    }
    ._customer-data{
        font-size: 20px;
        font-weight: 550 ;
        margin-left: 8px;
        margin-bottom: 5px;
    }
    ._data-difference{
        font-size: 16px;
        font-weight: 550;
        margin-top: 6px;
        margin-left: 10px;
    }
    ._customer-data-arrow{
        margin-top: 8px;
    }
    
    ._customers-card-footer{
        font-size: 14px;
    }
    ._ant-row{
        display: flex;
        flex-direction: column;
    }
    ._customers-card, ._users-card, ._devices-card{
        height: 131px;
        width: 330px;
        margin: 0 4px 12px 4px !important;
    }
    ._OCS-customers-card, ._OCS-users-card, ._OCS-devices-card{
        height: 131px;
        width: 330px;
        margin: 0 4px 12px 4px !important;
        padding-left: 30px;
        font-size: 14px;
    }
    ._Dgraf-header{
        margin-bottom: 10px;
    }
    ._deliverys-graf{
        width: 330px;
    }
    ._OCS-deliverys-graf{
        width: 330px;
    }
    ._DG-cards{
        display: flex;
        flex-direction: column;
        max-width: 290px;
    }
    ._documents-card {
        height: 350px;
        width: 230px;
        margin: 0 18px 20px 10px;
    }
    ._groups-card{
        height: 350px;
        width: 230px;
        margin: 0 8px 20px 10px;
    }
    ._OCS-DG-cards{
        display: flex;
        flex-direction: column;
        width: 490px;
    }
    ._OCS-documents-card {
        height: 350px;
        width: 330px;
        margin: 0 18px 20px 10px;
    }
    ._OCS-groups-card{
        height: 350px;
        width: 330px;
        margin: 0 8px 20px 10px;
    }
}
@media screen and (max-width: 411px){
    ._dashboard-container{
        left: 280px;
        display: inline-block;
    }
    
    ._OCS-dashboard-container{
        left: 83px;
        display: inline-block;

    }
    ._CUDD-cards{
        max-width: 475px;
    }
    
    ._OCS-CUDD-cards{
        max-width: 670px;
    }
    ._card-header{
        font-size: 18px;
    }
    ._customer-data{
        font-size: 20px;
        font-weight: 550;
        margin-left: 8px;
        margin-bottom: 5px;
    }
    ._data-difference{
        font-size: 16px;
        font-weight: 550;
        margin-top: 6px;
        margin-left: 10px;
    }
    ._customer-data-arrow{
        margin-top: 8px;
    }
    
    ._customers-card-footer{
        font-size: 14px;
    }
    ._ant-row{
        display: flex;
        flex-direction: column;
    }
    ._customers-card, ._users-card, ._devices-card{
        height: 131px;
        width: 315px;
        margin: 0 4px 12px 4px !important;
    }
    ._OCS-customers-card, ._OCS-users-card, ._OCS-devices-card{
        height: 131px;
        width: 315px;
        margin: 0 4px 12px 4px !important;
        padding-left: 30px;
        font-size: 14px;
    }
    ._Dgraf-header{
        margin-bottom: 10px;
    }
    ._deliverys-graf{
        width: 315px;
    }
    ._OCS-deliverys-graf{
        width: 315px;
    }
    ._DG-cards{
        display: flex;
        flex-direction: column;
        max-width: 290px;
    }
    ._documents-card {
        height: 350px;
        width: 230px;
        margin: 0 18px 20px 10px;
    }
    ._groups-card{
        height: 350px;
        width: 230px;
        margin: 0 8px 20px 10px;
    }
    ._OCS-DG-cards{
        display: flex;
        flex-direction: column;
        width: 490px;
    }
    ._OCS-documents-card {
        height: 350px;
        width: 315px;
        margin: 0 18px 20px 10px;
    }
    ._OCS-groups-card{
        height: 350px;
        width: 315px;
        margin: 0 8px 20px 10px;
    }
}
@media screen and (max-width: 390px){
    ._dashboard-container{
        left: 280px;
        display: inline-block;
    }
    
    ._OCS-dashboard-container{
        left: 83px;
        display: inline-block;

    }
    ._CUDD-cards{
        max-width: 475px;
    }
    
    ._OCS-CUDD-cards{
        max-width: 670px;
    }
    ._card-header{
        font-size: 18px;
    }
    ._customer-data{
        font-size: 20px;
        font-weight: 550;
        margin-left: 8px;
        margin-bottom: 5px;
    }
    ._data-difference{
        font-size: 16px;
        font-weight: 550;
        margin-top: 6px;
        margin-left: 10px;
    }
    ._customer-data-arrow{
        margin-top: 8px;
    }
    
    ._customers-card-footer{
        font-size: 14px;
    }
    ._ant-row{
        display: flex;
        flex-direction: column;
    }
    ._customers-card, ._users-card, ._devices-card{
        height: 111px;
        width: 295px;
        margin: 0 4px 12px 4px !important;
    }
    ._OCS-customers-card, ._OCS-users-card, ._OCS-devices-card{
        height: 111px;
        width: 295px;
        margin: 0 4px 12px 4px !important;
        padding-left: 30px;
        font-size: 14px;
    }
    ._Dgraf-header{
        margin-bottom: 10px;
    }
    ._deliverys-graf{
        width: 295px;
    }
    ._OCS-deliverys-graf{
        width: 295px;
    }
    ._DG-cards{
        display: flex;
        flex-direction: column;
        max-width: 290px;
    }
    ._documents-card {
        height: 350px;
        width: 230px;
        margin: 0 18px 20px 10px;
    }
    ._groups-card{
        height: 350px;
        width: 230px;
        margin: 0 8px 20px 10px;
    }
    ._OCS-DG-cards{
        display: flex;
        flex-direction: column;
        width: 490px;
    }
    ._OCS-documents-card {
        height: 350px;
        width: 295px;
        margin: 0 18px 20px 10px;
    }
    ._OCS-groups-card{
        height: 350px;
        width: 295px;
        margin: 0 8px 20px 10px;
    }
}
@media screen and (max-width: 375px){
    ._dashboard-container{
        left: 280px;
        display: inline-block;
    }
    
    ._OCS-dashboard-container{
        left: 83px;
        display: inline-block;

    }
    ._CUDD-cards{
        max-width: 475px;
    }
    
    ._OCS-CUDD-cards{
        max-width: 670px;
    }
    ._card-header{
        font-size: 18px;
    }
    ._customer-data{
        font-size: 20px;
        font-weight: 550;
        margin-left: 8px;
        margin-bottom: 5px;
    }
    ._data-difference{
        font-size: 16px;
        font-weight: 550;
        margin-top: 6px;
        margin-left: 10px;
    }
    ._customer-data-arrow{
        margin-top: 8px;
    }
    
    ._customers-card-footer{
        font-size: 14px;
    }
    ._ant-row{
        display: flex;
        flex-direction: column;
    }
    ._customers-card, ._users-card, ._devices-card{
        height: 111px;
        width: 280px;
        margin: 0 4px 12px 4px !important;
    }
    ._OCS-customers-card, ._OCS-users-card, ._OCS-devices-card{
        height: 111px;
        width: 280px;
        margin: 0 4px 12px 4px !important;
        padding-left: 30px;
        font-size: 14px;
    }
    ._Dgraf-header{
        margin-bottom: 10px;
    }
    ._deliverys-graf{
        width: 280px;
    }
    ._OCS-deliverys-graf{
        width: 280px;
    }
    ._DG-cards{
        display: flex;
        flex-direction: column;
        max-width: 290px;
    }
    ._documents-card {
        height: 350px;
        width: 230px;
        margin: 0 18px 20px 10px;
    }
    ._groups-card{
        height: 350px;
        width: 230px;
        margin: 0 8px 20px 10px;
    }
    ._OCS-DG-cards{
        display: flex;
        flex-direction: column;
        width: 490px;
    }
    ._OCS-documents-card {
        height: 350px;
        width: 280px;
        margin: 0 18px 20px 10px;
    }
    ._OCS-groups-card{
        height: 350px;
        width: 280px;
        margin: 0 8px 20px 10px;
    }
}
@media screen and (max-width: 360px){
    ._dashboard-container{
        left: 280px;
        display: inline-block;
    }
    
    ._OCS-dashboard-container{
        left: 83px;
        display: inline-block;

    }
    ._CUDD-cards{
        max-width: 475px;
    }
    
    ._OCS-CUDD-cards{
        max-width: 670px;
    }
    ._card-header{
        font-size: 18px;
    }
    ._customer-data{
        font-size: 20px;
        font-weight: 550;
        margin-left: 8px;
        margin-bottom: 5px;
    }
    ._data-difference{
        font-size: 16px;
        font-weight: 550;
        margin-top: 6px;
        margin-left: 10px;
    }
    ._customer-data-arrow{
        margin-top: 8px;
    }
    
    ._customers-card-footer{
        font-size: 14px;
    }
    ._ant-row{
        display: flex;
        flex-direction: column;
    }
    ._customers-card, ._users-card, ._devices-card{
        height: 111px;
        width: 264px;
        margin: 0 4px 12px 4px !important;
    }
    ._OCS-customers-card, ._OCS-users-card, ._OCS-devices-card{
        height: 111px;
        width: 264px;
        margin: 0 4px 12px 4px !important;
        padding-left: 30px;
        font-size: 14px;
    }
    ._Dgraf-header{
        margin-bottom: 10px;
    }
    ._deliverys-graf{
        width: 264px;
    }
    ._OCS-deliverys-graf{
        width: 264px;
    }
    ._DG-cards{
        display: flex;
        flex-direction: column;
        max-width: 290px;
    }
    ._documents-card {
        height: 350px;
        width: 230px;
        margin: 0 18px 20px 10px;
    }
    ._groups-card{
        height: 350px;
        width: 230px;
        margin: 0 8px 20px 10px;
    }
    ._OCS-DG-cards{
        display: flex;
        flex-direction: column;
        width: 490px;
    }
    ._OCS-documents-card {
        height: 350px;
        width: 264px;
        margin: 0 18px 20px 10px;
    }
    ._OCS-groups-card{
        height: 350px;
        width: 264px;
        margin: 0 8px 20px 10px;
    }
}