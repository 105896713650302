


._Pointer{
    cursor:pointer;
}


._table-responsive{
    margin-top: 50px;
    width: 1151px;
    transition: .3s;
}
._OCS-table-responsive{
    margin-top: 50px;
    width: 1351px;
    transition: .3s;
}

._ant-add-top-table-btn{
    float: right;
}

._more-btn {
    border: none;
    border-radius: 100%;
    background-color: inherit;
    padding: 4px 4px 0px 4px
}

._more-btn:hover {
    background-color: #f0f0f0;
}


._ops-option{
    padding: 8px 25px 2px 10px;
    cursor: pointer;
}

._ops-option:hover{
    background-color: #f0f0f0;
}

@media screen and (max-width: 1490px){
    ._table-responsive{
        width: 1000px;
    }
    ._OCS-table-responsive{
        width: 1200px;
    }
}
@media screen and (max-width: 1400px){
    ._table-responsive{
        width: 910px;
    }
    ._OCS-table-responsive{
        width: 1100px;
    }
}
@media screen and (max-width: 1270px){
    ._table-responsive{
        width: 750px;
    }
    ._OCS-table-responsive{
        width: 940px;
    }
}
@media screen and (max-width: 1100px){
    ._table-responsive{
        width: 640px;
    }
    ._OCS-table-responsive{
        width: 830px;
    }
}
@media screen and (max-width: 990px){
    ._table-responsive{
        width: 510px;
    }
    ._OCS-table-responsive{
        width: 690px;
    }
}
@media screen and (max-width: 860px){
    ._table-responsive{
        width: 420px;
    }
    ._OCS-table-responsive{
        width: 590px;
    }
}
@media screen and (max-width: 730px){
    ._table-responsive{
        width: 380px;
    }
    ._OCS-table-responsive{
        width: 570px;
    }
}
@media screen and (max-width: 610px){
    ._table-responsive{
        width: 250px;
    }
    ._OCS-table-responsive{
        width: 435px;
    }
}
@media screen and (max-width: 490px){
    ._table-responsive{
        width: 250px;
    }
    ._OCS-table-responsive{
        width: 335px;
    }
}
@media screen and (max-width: 430px){
    ._table-responsive{
        width: 300px;
    }
    ._OCS-table-responsive{
        width: 300px;
    }
}
@media screen and (max-width: 411px){
    ._table-responsive{
        width: 290px;
    }
    ._OCS-table-responsive{
        width: 290px;
    }
    ._ant-add-top-table-btn{
        float: unset;
        margin: 10px 10px -15px -5px !important;
    }
}
@media screen and (max-width: 390px){
    ._table-responsive{
        width: 272px;
    }
    ._OCS-table-responsive{
        width: 272px;
    }
    ._ant-add-top-table-btn{
        float: unset;
        margin: 10px 10px -15px -10px !important;
    }
}
@media screen and (max-width: 375px){
    ._table-responsive{
        width: 258px;
    }
    ._OCS-table-responsive{
        width: 258px;
    }
    ._ant-add-top-table-btn{
        float: unset;
        margin: 10px 10px -15px -8px !important;
    }
}
@media screen and (max-width: 360px){
    ._table-responsive{
        width: 240px;
    }
    ._OCS-table-responsive{
        width: 240px;
    }
    ._ant-add-top-table-btn{
        float: unset;
        margin: 10px 10px -15px -8px !important;
    }
}