.grid-container {
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(250px, 1fr)); /* Adapta el número de columnas según el tamaño */
    gap: 20px; /* Espacio entre los elementos */
    padding: 20px;
  }
  
  .grid-row {
    display: contents; /* Esto permite que los elementos hijos se adhieran a la configuración de la cuadrícula del contenedor */
  }
  
  ._OCS-devices-card, ._devices-card, ._OCS-customers-card, ._customers-card, ._OCS-users-card, ._users-card {
    background-color: #f9f9f9;
    border-radius: 8px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    padding: 20px;
    text-align: center;
    transition: transform 0.3s ease;
  }
  
  ._OCS-devices-card:hover, ._devices-card:hover, ._OCS-customers-card:hover, ._customers-card:hover, ._OCS-users-card:hover, ._users-card:hover {
    transform: scale(1.05); /* Pequeño efecto de zoom al hacer hover */
  }
  
  ._customer-card-data {
    margin-bottom: 15px;
  }
  
  ._customer-data {
    font-size: 1.2rem;
    font-weight: bold;
  }
  
  ._customers-card-footer {
    font-size: 0.9rem;
    color: #666;
  }
  