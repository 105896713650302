._add-group-tooltip::before,
._add-group-tooltip::after {
    --scale: 0;
    --arrow-size: 10px;
    --tooltip-color: #333;
    position: absolute;
    top: -.25rem;
    left: inherit;
    transform: translateX(-50%) translateY(var(--translate-y, 0)) scale(var(--scale));
    transition: 150ms transform;
    transform-origin: bottom center;
}

._add-group-tooltip::before {
    --translate-y: calc(-100% - var(--arrow-size));
    content: attr(data-tooltip);
    color: white;
    padding: .5rem;
    border-radius: .3rem;
    text-align: center;
    width: max-content;
    max-width: 100%;
    background: var(--tooltip-color);
}

._add-group-tooltip:hover::before,
._add-group-tooltip:hover::after {
    --scale: 1;
}

._add-group-tooltip::after {
    --translate-y: calc(-1.1 * var(--arrow-size));
    content: '';
    border: var(--arrow-size) solid transparent;
    border-top-color: var(--tooltip-color);
    transform-origin: top center;
}

._clear-form-btn-tooltip::before,
._clear-form-btn-tooltip::after {
    --scale: 0;
    --arrow-size: 10px;
    --tooltip-color: #333;
    position: absolute;
    top: inherit;
    left: inherit;
    transform: translateX(-50%) translateY(var(--translate-y, 0)) scale(var(--scale));
    transition: 150ms transform;
    transform-origin: bottom center;
}

._clear-form-btn-tooltip::before {
    --translate-y: calc(-100% - var(--arrow-size));
    content: attr(data-tooltip);
    color: white;
    padding: .5rem;
    border-radius: .3rem;
    text-align: center;
    width: max-content;
    max-width: 100%;
    background: var(--tooltip-color);
}

._clear-form-btn-tooltip:hover::before,
._clear-form-btn-tooltip:hover::after {
    --scale: 1;
}

._clear-form-btn-tooltip:hover::after{
    transform: translateX(-97%) translateY(var(--translate-y, 0));
}

._clear-form-btn-tooltip::after {
    --translate-y: calc(-1.1 * var(--arrow-size));
    content: '';
    border: var(--arrow-size) solid transparent;
    border-top-color: var(--tooltip-color);
    transform-origin: top center;
}

._search-btn-tooltip::before,
._search-btn-tooltip::after {
    --scale: 0;
    --arrow-size: 10px;
    --tooltip-color: #333;
    position: absolute;
    top: -.25rem;
    left: 50%;
    transform: translateX(-50%) translateY(var(--translate-y, 0)) scale(var(--scale));
    transition: 150ms transform;
    transform-origin: bottom center;
}

._search-btn-tooltip::before {
    --translate-y: calc(-100% - var(--arrow-size));
    content: attr(data-tooltip);
    color: white;
    padding: .5rem;
    border-radius: .3rem;
    text-align: center;
    width: max-content;
    max-width: 100%;
    background: var(--tooltip-color);
}

._search-btn-tooltip:hover::before,
._search-btn-tooltip:hover::after {
    --scale: 1;
}

._search-btn-tooltip::after {
    --translate-y: calc(-1.1 * var(--arrow-size));
    content: '';
    border: var(--arrow-size) solid transparent;
    border-top-color: var(--tooltip-color);
    transform-origin: top center;
}

._edit-btn-tooltip::before,
._edit-btn-tooltip::after {
    --scale: 0;
    --arrow-size: 10px;
    --tooltip-color: #333;
    position: absolute;
    top: inherit;
    left: inherit;
    transform: translateX(-20%) translateY(var(--translate-y, 0)) scale(var(--scale));
    transition: 150ms transform;
    transform-origin: bottom center;
}

._edit-btn-tooltip::before {
    --translate-y: calc(-100% - var(--arrow-size));
    content: attr(data-tooltip);
    color: white;
    padding: .5rem;
    border-radius: .3rem;
    text-align: center;
    width: max-content;
    max-width: 100%;
    background: var(--tooltip-color);
}

._edit-btn-tooltip:hover::before,
._edit-btn-tooltip:hover::after {
    --scale: 1;
}
._edit-btn-tooltip:hover::after{
    transform: translateX(-70%) translateY(var(--translate-y, 0));

}

._edit-btn-tooltip::after {
    --translate-y: calc(-1.1 * var(--arrow-size));
    content: '';
    border: var(--arrow-size) solid transparent;
    border-top-color: var(--tooltip-color);
    transform-origin: top center;
}

._delete-btn-tooltip::before,
._delete-btn-tooltip::after {
    --scale: 0;
    --arrow-size: 10px;
    --tooltip-color: #333;
    position: absolute;
    top: inherit;
    left: inherit;
    transform: translateX(-20%) translateY(var(--translate-y, 0)) scale(var(--scale));
    transition: 150ms transform;
    transform-origin: bottom center;
}

._delete-btn-tooltip::before {
    --translate-y: calc(-100% - var(--arrow-size));
    content: attr(data-tooltip);
    color: white;
    padding: .5rem;
    border-radius: .3rem;
    text-align: center;
    width: max-content;
    max-width: 100%;
    background: var(--tooltip-color);
}

._delete-btn-tooltip:hover::before,
._delete-btn-tooltip:hover::after {
    --scale: 1;
}
._delete-btn-tooltip:hover::after{
    transform: translateX(-90%) translateY(var(--translate-y, 0));

}

._delete-btn-tooltip::after {
    --translate-y: calc(-1.1 * var(--arrow-size));
    content: '';
    border: var(--arrow-size) solid transparent;
    border-top-color: var(--tooltip-color);
    transform-origin: top center;
}