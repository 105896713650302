._ant-card-body{
    display: block;
    padding: 10px 20px 20px 20px;
    color: #455560;
    background-color: white;
    width: max-content;
    margin-bottom: 20px;
    margin-left: 300px;
    border: 1px solid #e6ebf1;
    border-radius: .635rem;
    transition: .3s;
}
._OCS-ant-card-body{
    display: block;
    padding: 10px 20px 20px 20px;
    color: #455560;
    background-color: white;
    width: max-content;
    margin-bottom: 20px;
    margin-left: 110px;
    border: 1px solid #e6ebf1;
    border-radius: .635rem;
    transition: .3s;
}
._ant-card-header{
    font-weight: 700;
    padding: 3px 0 20px;
    overflow: hidden;
    font-size: 17px;
    color: #1a3353;
}

._ant-card-form-group {
    width: 886px;
}
._OCS-ant-card-form-group {
    width: 1086px;
}

.ant-col {
    width: 800px;
}

._ant-form-wrapper{
    display: flex;
    flex-direction: row;
}

._ant-form-wrapper .ant-col {
    width: 420px;
    padding-left: 8px;
    padding-right: 8px;
}

.ant-form-item-label{
    font-weight: 700;
}

.ant-input, .ant-picker{
    border-radius: .625rem;
}

#loginForm > div:nth-child(2) > div.ant-col.ant-col-100.ant-form-item-control > div > div > span{
    border-radius: .625rem;
}

.ant-select:not(.ant-select-customize-input) .ant-select-selector{
    border-radius: .625rem;
}

@media screen and (max-width: 1270px){
    ._ant-card-body{
        padding: 10px 20px 20px 20px;
        width: max-content;
        margin-bottom: 20px;
        margin-left: 300px;
    }
    ._OCS-ant-card-body{
        padding: 10px 20px 20px 20px;
        width: max-content;
        margin-bottom: 20px;
        margin-left: 110px;
    }
    ._ant-card-form-group {
        width: 786px;
    }
    ._OCS-ant-card-form-group {
        width: 966px;
    }
    .ant-col {
        width: 700px;
    }
    
    ._ant-form-wrapper .ant-col {
        width: 380px;
        padding-left: 8px;
        padding-right: 8px;
    }
}
@media screen and (max-width: 1100px){
    ._ant-card-body{
        padding: 10px 20px 20px 20px;
        width: max-content;
        margin-bottom: 20px;
        margin-left: 300px;
    }
    ._OCS-ant-card-body{
        padding: 10px 20px 20px 20px;
        width: max-content;
        margin-bottom: 20px;
        margin-left: 110px;
    }
    ._ant-card-form-group {
        width: 656px;
    }
    ._OCS-ant-card-form-group {
        width: 836px;
    }
    .ant-col {
        width: 600px;
    }
    
    ._ant-form-wrapper .ant-col {
        width: 300px;
        padding-left: 8px;
        padding-right: 8px;
        padding-top: 8px;
    }
    .ant-form-item-label{
        display: flex;
    }
}
@media screen and (max-width: 990px){
    ._ant-card-body{
        padding: 10px 20px 20px 20px;
        width: max-content;
        margin-bottom: 20px;
        margin-left: 300px;
    }
    ._OCS-ant-card-body{
        padding: 10px 20px 20px 20px;
        width: max-content;
        margin-bottom: 20px;
        margin-left: 110px;
    }
    ._ant-card-form-group {
        width: 546px;
    }
    ._OCS-ant-card-form-group {
        width: 716px;
    }
    .ant-col {
        width: 500px;
    }
    
    ._ant-form-wrapper .ant-col {
        width: 250px;
        padding-left: 8px;
        padding-right: 8px;
        padding-top: 8px;
    }
    .ant-form-item-label{
        display: flex;
        flex-direction: row;
    }
}
@media screen and (max-width: 860px){
    ._ant-card-body{
        padding: 10px 20px 20px 20px;
        width: max-content;
        margin-bottom: 20px;
        margin-left: 300px;
    }
    ._OCS-ant-card-body{
        padding: 10px 20px 20px 20px;
        width: max-content;
        margin-bottom: 20px;
        margin-left: 110px;
    }
    ._ant-card-form-group {
        width: 446px;
    }
    ._OCS-ant-card-form-group {
        width: 616px;
    }
    .ant-col {
        width: 400px;
    }
    ._ant-form-wrapper{
        flex-direction: column;
    }
    ._ant-form-wrapper .ant-col {
        width: 400px;
        padding-left: 8px;
        padding-right: 8px;
        padding-top: 8px;
    }
    .ant-form-item-label{
        display: flex;
        flex-direction: row;
    }
}
@media screen and (max-width: 730px){
    ._ant-card-body{
        padding: 10px 20px 20px 20px;
        width: max-content;
        margin-bottom: 20px;
        margin-left: 300px;
    }
    ._OCS-ant-card-body{
        padding: 10px 20px 20px 20px;
        width: max-content;
        margin-bottom: 20px;
        margin-left: 110px;
    }
    ._ant-card-form-group {
        width: 416px;
    }
    ._OCS-ant-card-form-group {
        width: 600px;
    }
    .ant-col {
        width: 390px;
    }
    ._ant-form-wrapper{
        flex-direction: column;
    }
    ._ant-form-wrapper .ant-col {
        width: 400px;
        padding-left: 8px;
        padding-right: 8px;
        padding-top: 8px;
    }
    .ant-form-item-label{
        display: flex;
        flex-direction: row;
    }
}
@media screen and (max-width: 610px){
    ._ant-card-body{
        padding: 10px 20px 20px 20px;
        width: max-content;
        margin-bottom: 20px;
        margin-left: 300px;
    }
    ._OCS-ant-card-body{
        padding: 10px 20px 20px 20px;
        width: max-content;
        margin-bottom: 20px;
        margin-left: 110px;
    }
    ._ant-card-form-group {
        width: 290px;
    }
    ._OCS-ant-card-form-group {
        width: 480px;
    }
    .ant-col {
        width: 270px;
    }
    ._ant-form-wrapper{
        flex-direction: column;
    }
    ._ant-form-wrapper .ant-col {
        width: 270px;
        padding-left: 8px;
        padding-right: 8px;
        padding-top: 8px;
    }
    .ant-form-item-label{
        display: flex;
        flex-direction: row;
    }
}
@media screen and (max-width: 610px){
    ._ant-card-body{
        padding: 10px 20px 20px 20px;
        width: max-content;
        margin-bottom: 20px;
        margin-left: 300px;
    }
    ._OCS-ant-card-body{
        padding: 10px 20px 20px 20px;
        width: max-content;
        margin-bottom: 20px;
        margin-left: 110px;
    }
    ._ant-card-form-group {
        width: 290px;
    }
    ._OCS-ant-card-form-group {
        width: 480px;
    }
    .ant-col {
        width: 270px;
    }
    ._ant-form-wrapper{
        flex-direction: column;
    }
    ._ant-form-wrapper .ant-col {
        width: 270px;
        padding-left: 8px;
        padding-right: 8px;
        padding-top: 8px;
    }
    .ant-form-item-label{
        display: flex;
        flex-direction: row;
    }
}
@media screen and (max-width: 490px){
    ._ant-card-body{
        padding: 10px 20px 20px 20px;
        width: max-content;
        margin-bottom: 20px;
        margin-left: 300px;
    }
    ._OCS-ant-card-body{
        padding: 10px 20px 20px 20px;
        width: max-content;
        margin-bottom: 20px;
        margin-left: 110px;
    }
    ._ant-card-form-group {
        width: 370px;
    }
    ._OCS-ant-card-form-group {
        width: 370px;
    }
    .ant-row{
        flex-direction: row;
        flex-wrap: wrap;
    }
    .ant-col {
        width: 300px;
    }
    ._ant-form-wrapper{
        flex-direction: column;
    }
    ._ant-form-wrapper .ant-col {
        width: 300px;
        padding-left: 8px;
        padding-right: 8px;
        padding-top: 8px;
    }
    .ant-form-item{
        margin-bottom: 10px;
    }
    .ant-form-item-label{
        display: flex;
        flex-direction: row;
    }
}
@media screen and (max-width: 430px){
    ._ant-card-body{
        padding: 10px 20px 20px 20px;
        width: 340px;
        margin-bottom: 20px;
        margin-left: 300px;
    }
    ._OCS-ant-card-body{
        padding: 10px 20px 20px 20px;
        width: 340px;
        margin-bottom: 20px;
        margin-left: 83px;
    }
    ._ant-card-form-group {
        width: 340px;
    }
    ._OCS-ant-card-form-group {
        width: 340px;
    }
    .ant-row{
        flex-direction: row;
        flex-wrap: wrap;
    }
    .ant-col {
        width: 300px;
    }
    ._ant-form-wrapper{
        flex-direction: column;
    }
    ._ant-form-wrapper .ant-col {
        width: 300px;
        padding-left: 8px;
        padding-right: 8px;
        padding-top: 8px;
    }
    .ant-form-item{
        margin-bottom: 10px;
    }
    .ant-form-item-label{
        display: flex;
        flex-direction: row;
    }
}
@media screen and (max-width: 411px){
    ._ant-card-body{
        padding: 10px 20px 20px 20px;
        width: 320px;
        margin-bottom: 20px;
        margin-left: 300px;
    }
    ._OCS-ant-card-body{
        padding: 10px 20px 20px 20px;
        width: 320px;
        margin-bottom: 20px;
        margin-left: 83px;
    }
    ._ant-card-form-group {
        width: 323px;
    }
    ._OCS-ant-card-form-group {
        width: 323px;
    }
    .ant-row{
        flex-direction: row;
        flex-wrap: wrap;
    }
    .ant-col {
        width: 290px;
    }
    ._ant-form-wrapper{
        flex-direction: column;
    }
    ._ant-form-wrapper .ant-col {
        width: 290px;
        padding-left: 8px;
        padding-right: 8px;
        padding-top: 8px;
    }
    .ant-form-item{
        margin-bottom: 10px;
    }
    .ant-form-item-label{
        display: flex;
        flex-direction: row;
    }
}
@media screen and (max-width: 390px){
    ._ant-card-body{
        padding: 10px 20px 20px 20px;
        width: 303px;
        margin-bottom: 20px;
        margin-left: 300px;
    }
    ._OCS-ant-card-body{
        padding: 10px 20px 20px 20px;
        width: 303px;
        margin-bottom: 20px;
        margin-left: 83px;
    }
    ._ant-card-form-group {
        width: 303px;
    }
    ._OCS-ant-card-form-group {
        width: 303px;
    }
    .ant-row{
        flex-direction: row;
        flex-wrap: wrap;
    }
    .ant-col {
        width: 280px;
    }
    ._ant-form-wrapper{
        flex-direction: column;
    }
    ._ant-form-wrapper .ant-col {
        width: 280px;
        padding-left: 8px;
        padding-right: 8px;
        padding-top: 8px;
    }
    .ant-form-item{
        margin-bottom: 10px;
    }
    .ant-form-item-label{
        display: flex;
        flex-direction: row;
    }
}
@media screen and (max-width: 375px){
    ._ant-card-body{
        padding: 10px 20px 20px 20px;
        width: 288px;
        margin-bottom: 20px;
        margin-left: 300px;
    }
    ._OCS-ant-card-body{
        padding: 10px 20px 20px 20px;
        width: 288px;
        margin-bottom: 20px;
        margin-left: 83px;
    }
    ._ant-card-form-group {
        width: 288px;
    }
    ._OCS-ant-card-form-group {
        width: 288px;
    }
    .ant-row{
        flex-direction: row;
        flex-wrap: wrap;
    }
    .ant-col {
        width: 260px;
        margin-left: 13px;
    }
    ._ant-form-wrapper{
        flex-direction: column;
    }
    ._ant-form-wrapper .ant-col {
        width: 260px;
        padding-left: 8px;
        padding-right: 8px;
        padding-top: 8px;
    }
    .ant-form-item{
        margin-bottom: 10px;
    }
    .ant-form-item-label{
        display: flex;
        flex-direction: row;
    }
}
@media screen and (max-width: 360px){
    ._ant-card-body{
        padding: 10px 20px 20px 20px;
        width: 275px;
        margin-bottom: 20px;
        margin-left: 300px;
    }
    ._OCS-ant-card-body{
        padding: 10px 20px 20px 20px;
        width: 275px;
        margin-bottom: 20px;
        margin-left: 83px;
    }
    ._ant-card-form-group {
        width: 275px;
    }
    ._OCS-ant-card-form-group {
        width: 275px;
    }
    .ant-row{
        flex-direction: row;
        flex-wrap: wrap;
    }
    .ant-col {
        width: 250px;
        margin-left: 13px;
    }
    ._ant-form-wrapper{
        flex-direction: column;
    }
    ._ant-form-wrapper .ant-col {
        width: 250px;
        padding-left: 8px;
        padding-right: 8px;
        padding-top: 8px;
    }
    .ant-form-item{
        margin-bottom: 10px;
    }
    .ant-form-item-label{
        display: flex;
        flex-direction: row;
    }
}