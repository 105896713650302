._navbar-container{
    position: sticky;
    z-index: 5;
    background-color: #fff;
    display: flex;
    justify-content: space-between;
    flex-direction: row;
    box-shadow: #00000026 0px 1px 5px -1px;
    height: 60px;
    width: 100%;
    top: 0vh;
}

._handdo-png {
    width: 124px;
    height: 45px; 
    margin-top: 5px;
    margin-left: 55px;
    margin-right: 80px;
    transition: all  0.5s;
    object-fit: cover;
    content:url(../../../resources/logos/handdoLogoNew.png);
}
._OCS-handdoH-png {
    width: 40px;
    height: 40px;
    margin-top: 10px;
    margin-left: 16px;
    margin-right: 20px;
    object-fit: scale-down;
    transition: all  0.5s;
    content:url(../../../resources/logos/handdoLogoNew2.png);
}

._menu-btn{
    margin-right: 15px;
    top: 1vh;
    left: 1.2vw;
    outline: none;
    border: none;
    background-color: inherit;
}

#pageSelect{
    height: 40px;
    width: 300px;
    margin-top: 12px;
    margin-left: 7px;
    background-color: #fafafb;
    border-color: #e6ebf1;
    border-radius: 10px;
    color: #4d4b4bfb;
    outline: none;
    font-size: 14px;
}
._pageOption{
    background-color: #fffbfbc9;
}

._logo-username-container {
    display: flex;
    justify-content: space-between;
    flex-direction: row;
}

._navbar-user-icon {
    display: flex;
    justify-content: space-between;
    flex-direction: row;
    margin-right: 1vw;
    margin-top: 1vh;
}

._navbar-icon {
    margin-top: 6px;
    margin-left: 15px;
    margin-right: 15px;
}

@media screen and (max-width: 1490px){
    ._OCS-handdoH-png{
        width: 40px;
        height: 40px;
        margin-top: 10px;
        margin-left: 16px;
        margin-right: 20px;
    }
    #pageSelect{
        height: 38px;
    }
 
}
@media screen and (max-width: 1400px){
    ._OCS-handdoH-png{
        width: 40px;
        height: 40px;
        margin-top: 10px;
        margin-left: 16px;
        margin-right: 20px;
    }
    #pageSelect{
        height: 38px;
    }
 
}
@media screen and (max-width: 1270px){
    ._handdo-png {
        width: 124px;
        height: 45px; 
        margin-top: 5px;
        margin-left: 55px;
        margin-right: 80px;
    }
    ._OCS-handdoH-png{
        width: 40px;
        height: 40px;
        margin-top: 10px;
        margin-left: 16px;
        margin-right: 20px;
    }
    #pageSelect{
        height: 38px;
        width: 290px;
        margin-top: 11px;
        margin-left: 6px;
    }
 
}
@media screen and (max-width: 1100px){
    ._handdo-png {
        width: 124px;
        height: 45px; 
        margin-top: 5px;
        margin-left: 55px;
        margin-right: 80px;
    }
    ._OCS-handdoH-png{
        width: 40px;
        height: 40px;
        margin-top: 10px;
        margin-left: 16px;
        margin-right: 20px;
    }
    #pageSelect{
        height: 38px;
        width: 250px;
        margin-top: 11px;
        margin-left: 6px;
    }
 
}
@media screen and (max-width: 990px){
    ._handdo-png {
        width: 124px;
        height: 45px; 
        margin-top: 5px;
        margin-left: 55px;
        margin-right: 80px;
    }
    ._OCS-handdoH-png{
        width: 40px;
        height: 40px;
        margin-top: 10px;
        margin-left: 16px;
        margin-right: 20px;
    }
    #pageSelect{
        height: 38px;
        width: 250px;
        margin-top: 11px;
        margin-left: 6px;
    }
 
}
@media screen and (max-width: 860px){
    ._handdo-png {
        width: 124px;
        height: 45px; 
        margin-top: 5px;
        margin-left: 55px;
        margin-right: 80px;
    }
    ._OCS-handdoH-png{
        width: 40px;
        height: 40px;
        margin-top: 10px;
        margin-left: 16px;
        margin-right: 20px;
    }
    #pageSelect{
        height: 35px;
        width: 220px;
        margin-top: 11px;
        margin-left: 6px;
    }
 
}
@media screen and (max-width: 860px){
    ._handdo-png {
        width: 124px;
        height: 45px; 
        margin-top: 5px;
        margin-left: 55px;
        margin-right: 80px;
    }
    ._OCS-handdoH-png{
        width: 40px;
        height: 40px;
        margin-top: 10px;
        margin-left: 16px;
        margin-right: 20px;
    }
    #pageSelect{
        height: 35px;
        width: 220px;
        margin-top: 11px;
        margin-left: 6px;
    }
 
}
@media screen and (max-width: 730px){
    ._handdo-png {
        width: 124px;
        height: 45px; 
        margin-top: 5px;
        margin-left: 55px;
        margin-right: 80px;
    }
    ._OCS-handdoH-png{
        width: 40px;
        height: 40px;
        margin-top: 10px;
        margin-left: 16px;
        margin-right: 20px;
    }
    #pageSelect{
        height: 35px;
        width: 220px;
        margin-top: 11px;
        margin-left: 6px;
    }
 
}
@media screen and (max-width: 610px){
    ._handdo-png {
        width: 124px;
        height: 45px; 
        margin-top: 5px;
        margin-left: 55px;
        margin-right: 80px;
    }
    ._OCS-handdoH-png{
        width: 40px;
        height: 40px;
        margin-top: 10px;
        margin-left: 16px;
        margin-right: 20px;
    }
    #pageSelect{
        height: 35px;
        width: 200px;
        margin-top: 11px;
        margin-left: 6px;
    }
 
}
@media screen and (max-width: 490px){
    ._handdo-png {
        width: 124px;
    height: 45px; 
    margin-top: 5px;
    margin-left: 55px;
    margin-right: 80px;
    }
    ._OCS-handdoH-png{
        width: 40px;
        height: 40px;
        margin-top: 10px;
        margin-left: 16px;
        margin-right: 20px;
    }
    #pageSelect{
        height: 35px;
        width: 180px;
        margin-top: 11px;
        margin-left: 6px;
    }
 
}
@media screen and (max-width: 430px){
    ._handdo-png {
        width: 124px;
        height: 45px; 
        margin-top: 5px;
        margin-left: 55px;
        margin-right: 80px;
    }
    ._OCS-handdoH-png{
        width: 40px;
        height: 40px;
        margin-top: 10px;
        margin-left: 16px;
        margin-right: 20px;
    }
    #pageSelect{
        height: 32px;
        width: 180px;
        margin-top: 11px;
        margin-left: 6px;
    }
 
}
@media screen and (max-width: 411px){
    ._handdo-png {
        width: 124px;
        height: 45px; 
        margin-top: 5px;
        margin-left: 55px;
        margin-right: 80px;
    }
    ._OCS-handdoH-png{
        width: 40px;
        height: 40px;
        margin-top: 10px;
        margin-left: 16px;
        margin-right: 20px;
    }
    #pageSelect{
        height: 32px;
        width: 170px;
        margin-top: 11px;
        margin-left: 6px;
    }
 
}
@media screen and (max-width: 375px){
    ._handdo-png {
        width: 124px;
        height: 45px; 
        margin-top: 5px;
        margin-left: 55px;
        margin-right: 80px;
    }
    ._OCS-handdoH-png{
        width: 40px;
        height: 40px;
        margin-top: 10px;
        margin-left: 16px;
        margin-right: 20px;
    }
    ._menu-btn{
        margin-right: 10px;
        top: 1vh;
        left: 1.2vw;
        outline: none;
        border: none;
        background-color: inherit;
    }
    #pageSelect{
        height: 32px;
        width: 165px;
        margin-top: 11px;
        margin-left: 6px;
    }
 
}
@media screen and (max-width: 360px){
    ._handdo-png {
        width: 124px;
        height: 45px; 
        margin-top: 5px;
        margin-left: 55px;
        margin-right: 80px;
    }
    ._OCS-handdoH-png{
        width: 40px;
        height: 40px;
        margin-top: 10px;
        margin-left: 16px;
        margin-right: 20px;
    }
    ._menu-btn{
        margin-right: 8px;
        top: 1vh;
        left: 1.2vw;
        outline: none;
        border: none;
        background-color: inherit;
    }
    #pageSelect{
        height: 32px;
        width: 165px;
        margin-top: 11px;
        margin-left: 6px;
    }
 
}