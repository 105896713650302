._header-container{
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    background-color: #fff;
    border-bottom: 1px solid #e6ebf1;
    padding: 25px;
    margin-bottom: 20px;
    text-align: center;
}

/*******************Botones de crear y volver***************************/

._header-container3{
    /* display: flex; */
 /*    flex-direction: row;
    justify-content: space-between; */
    background-color: #fff;
    border-bottom: 1px solid #e6ebf1;
    padding: 25px;
    margin-bottom: 20px;
   /*  text-align: center; */
}


.-btn1-{
    margin-left: 1080px;
    background-color: #fff;
    
}

._btn2_{
    position: relative;
    margin-left: 300px;
    transition: .3s; 
    background-color: #fff;

}

._btn2-1_{
    position: relative;
    margin-left: 100px;
    transition: .3s;
    background-color: #fff;
   
}

._btns-container2{
    margin-top: 1px;
}

._header-containe2{
   /*  display: flex;
    flex-direction:row-reverse; */
    /* justify-content: space-between; */
    /* text-align: center; */
    /* margin-left: 280px; */
   
    background-color: #fff;
    border-bottom: 1px solid #e6ebf1;
    padding: 25px;
    margin-bottom: 20px;
}
/****************************************************************/

._btn-save-container{
    background-color: transparent !important;
    border: none !important;
    text-align: right;
}

._screen-header{
    position: relative;
    margin-top: 25px;
    margin-left: 300px;
    transition: .3s
}
._OCS-screen-header{
    position: relative;
    margin-top: 25px;
    margin-left: 110px;
    transition: .3s
}

h2{
    font-size: 22px;
    font-family: inherit;
    color: #1a3353;
    font-weight: 500;
    margin-left: 5px;
}

._btns-container{
    margin-top: 50px;
}

._discard-btn{
    margin-right: .5rem;
    line-height: 1.5;
    position: relative;
    display: inline-block;
    font-weight: 400;
    text-align: center;
    border: 1px solid transparent;
    box-shadow: 0 2px 0 #0000000b;
    cursor: pointer;
    height: 40px;
    padding: 8.5px 1.25rem;
    font-size: 14px;
    border-radius: .625rem;

    text-shadow: 0 -1px 0 #0000001f;
    color: #fff;
    border-color: #3e79f7;
    background:  #3e79f7;
    transition: all .3s cubic-bezier(.645,.045,.355,1);
}

._discard-btn:hover{
    background: #699dff;
}

._btn-primary{
    color: #fff;
    border-color: #3e79f7;
    background: #3e79f7;
    text-shadow: 0 -1px 0 #0000001f;
    box-shadow: 0 2px 0 #0000000b;
}

._btn-primary:hover{
    background: #699dff;
}

._ant-add-btn{
    outline: 0;
    line-height: 1.5;
    position: relative;
    display: inline-block;
    font-weight: 400;
    text-align: center;
    border: 1px solid transparent;
    box-shadow: 0 2px 0 #0000000b;
    cursor: pointer;
    height: 40px;
    padding: 8.5px 1.25rem;
    font-size: 14px;
    border-radius: .625rem;
    transition: all .3s cubic-bezier(.645,.045,.355,1);
}
._overlay{
    position: absolute;
    z-index: 3;
    background-color: #c2c2c283;
    height: 100%;
    width:100%;
    visibility: hidden;
}
._OCS-overlay{
    visibility: hidden;
}

@media screen and (max-width: 730px){
    ._screen-header{
        position: relative;
        margin-top: 10px;
        margin-left: 280px;
    }
    ._OCS-screen-header{
        position: relative;
        margin-top: 25px;
        margin-left: 100px;
    }
    
    h2{
        font-size: 20px;
        font-family: inherit;
        color: #1a3353;
        font-weight: 500;
    }

    ._btns-container{
        margin-top: 40px;
    }
    
    ._overlay{
        visibility: hidden;
    }
    ._OCS-overlay{
        visibility: hidden;
    }
}
@media screen and (max-width: 610px){
    ._header-container{
        display: flex;
        flex-direction: column;
    }
    ._screen-header{
        position: relative;
        margin-top: 10px;
        margin-left: 280px;
    }
    ._OCS-screen-header{
        position: relative;
        margin-top: 25px;
        margin-left: 100px;
    }
    
    h2{
        font-size: 20px;
        font-family: inherit;
        color: #1a3353;
        font-weight: 500;
    }

    ._btns-container{
        margin-top: 20px;
        margin-left: 260px;
    }

    ._discard-btn{
        width: 140px
    }
    ._ant-add-btn{
        width: 140px
    }

    ._overlay{
        visibility: hidden;
    }
    ._OCS-overlay{
        visibility: hidden;
    }
}
@media screen and (max-width: 490px){
    ._header-container{
        display: flex;
        flex-direction: column;
    }
    ._screen-header{
        position: relative;
        margin-top: 25px;
        margin-left: 256px;
    }
    ._OCS-screen-header{
        position: relative;
        margin-top: 25px;
        margin-left: 100px;
    }
    
    h2{
        font-size: 20px;
        font-family: inherit;
        color: #1a3353;
        font-weight: 500;
    }

    ._btns-container{
        margin-top: 20px;
        margin-left: 120px;
    }

    ._discard-btn{
        width: 300px;
    }
    ._ant-add-btn{
        width: 300px;
        margin-top: 10px;
        margin-bottom: 10px;
    }

    ._overlay{
        visibility: visible;
    }
    ._OCS-overlay{
        visibility: hidden;
    }
}
@media screen and (max-width: 430px){
    ._header-container{
        display: flex;
        flex-direction: column;
    }
    ._screen-header{
        position: relative;
        margin-top: 25px;
        margin-left: 256px;
    }
    ._OCS-screen-header{
        position: relative;
        margin-top: 15px;
        margin-left: 80px;
    }
    
    h2{
        font-size: 20px;
        font-family: inherit;
        color: #1a3353;
        font-weight: 500;
    }

    ._btns-container{
        margin-top: 20px;
        margin-left: 70px;
    }

    ._discard-btn{
        width: 280px;
    }
    ._ant-add-btn{
        width: 280px;
        margin-top: 10px;
        margin-bottom: 10px;
    }

    ._overlay{
        visibility: visible;
    }
    ._OCS-overlay{
        visibility: hidden;
    }
}
@media screen and (max-width: 375px){
    ._header-container{
        display: flex;
        flex-direction: column;
    }
    ._screen-header{
        position: relative;
        margin-top: 25px;
        margin-left: 256px;
    }
    ._OCS-screen-header{
        position: relative;
        margin-top: 15px;
        margin-left: 80px;
    }
    
    h2{
        font-size: 20px;
        font-family: inherit;
        color: #1a3353;
        font-weight: 500;
    }

    ._btns-container{
        margin-top: 20px;
        margin-left: 70px;
    }

    ._discard-btn{
        width: 260px;
    }
    ._ant-add-btn{
        width: 260px;
        margin-top: 10px;
        margin-bottom: 10px;
    }

    ._overlay{
        visibility: visible;
    }
    ._OCS-overlay{
        visibility: hidden;
    }
    #addDocumentBtn{
        margin-left: 15px;
    }
}
@media screen and (max-width: 360px){
    ._header-container{
        display: flex;
        flex-direction: column;
    }
    ._screen-header{
        position: relative;
        margin-top: 15px;
        margin-left: 256px;
    }
    ._OCS-screen-header{
        position: relative;
        margin-top: 15px;
        margin-left: 80px;
    }
    
    h2{
        font-size: 20px;
        font-family: inherit;
        color: #1a3353;
        font-weight: 500;
    }

    ._btns-container{
        margin-top: 20px;
        margin-left: 65px;
    }

    ._discard-btn{
        width: 250px;
    }
    ._ant-add-btn{
        width: 250px;
        margin-top: 10px;
        margin-bottom: 10px;
    }

    ._overlay{
        visibility: visible;
    }
    ._OCS-overlay{
        visibility: hidden;
    }
    #addDocumentBtn{
        margin-left: 15px;
    }
}