.Map{
    text-align: center;
    position: sticky;
    z-index: 0
}


.leaflet-container {
    
    margin: 0 auto;
    height: 500px;
    width: 800px;
    max-width: 100%;
    max-height: 100%;
   
  
}



@media screen and (max-width: 1400px){

    .leaflet-container {
    
        margin: 0 auto;
        height: 400px;
        width: 700px;
        max-width: 100%;
        max-height: 100%;
      
    }

}

@media screen and (max-width: 1270px){

    .leaflet-container {
    
        margin: 0 auto;
        height: 400px;
        width: 700px;
        max-width: 100%;
        max-height: 100%;
      
    }
}

@media screen and (max-width: 1100px){

    .leaflet-container {
    
        margin: 0 auto;
        height: 350px;
        width: 600px;
        max-width: 100%;
        max-height: 100%;
      
    }


}
