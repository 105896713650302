.pro-sidebar{
    height: 100% !important;
    position: fixed;
    top: 61px;
    z-index: 4;
}
.pro-sidebar > .pro-sidebar-inner{
    height: 100% !important;
    box-shadow: #00000026 0px 1px 4px -1px;
    background: #fff !important;
}

.pro-sidebar .pro-menu .pro-menu-item{
    font-weight: 500;
    font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
}

.pro-sidebar .pro-menu.shaped .pro-menu-item > .pro-inner-item > .pro-icon-wrapper{
    background-color: inherit;
}

.pro-sidebar .pro-menu .pro-menu-item.pro-sub-menu .pro-inner-list-item{
    background-color: #fff;
}

.pro-sidebar.collapsed .pro-menu > ul > .pro-menu-item.pro-sub-menu > .pro-inner-list-item > .popper-inner{
    background-color: #fff;
    box-shadow: #00000026 0px 1px 4px -1px;
}


._clicked-option {
    background-color: #87cefa4b;
    border-right: 3px solid #3e79f7;
    color: mediumblue !important;
}