._group-modal-header-container{
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    border-bottom: #6686ad 3px solid;
    margin-bottom: 22px;
    background-color: #416795;
}

._group-modal-span{
   font-weight: bold;
   padding-top: .4rem;
   padding-left: .5rem;
   color:#ebebeb;
}

._close-modal-button{
    border: none;
    background-color: inherit;
}
._close-modal-button:hover{
    filter: brightness(80%)
}

._modal-X-icon{
    height: unset;
    width: unset;
}

._modal-form{
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    padding: 5px;
}
._group-modal-bottom-buttons{
    display: flex;
    justify-content: space-evenly;
    position: relative;
    top: 240px;

}

._modal-bottom-button{
    border: none;
    border-radius: 2%;
    background-color: #dbdde0ad;
    box-shadow: 1px 1px 4px;
    height: 30px;
    width: 100px;
    font-weight: bold;
}

._group-modal-input, ._group-modal-SR{
    margin-bottom: 20px;
    height: 25px;
}

._group-modal-input, ._group-modal-SR{
    font-size: 17px;
}